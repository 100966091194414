import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from 'react-compare-slider';

import { useMediaQuery } from 'react-responsive';

export default ({ style, itemOneSrc, itemTwoSrc, ...props }) => {
  const isLgUnder = useMediaQuery({
    query: '(min-width:0px) and (max-width:1024px)',
  });
  return (
    <ReactCompareSlider
      {...props}
      onlyHandleDraggable={isLgUnder ? true : false}
      itemOne={
        <ReactCompareSliderImage
          src={itemOneSrc}
          alt="one"
          style={{
            objectFit: 'cover',
            width: '100%',
            overflow: 'hidden',
          }}
        />
      }
      itemTwo={
        <ReactCompareSliderImage
          src={itemTwoSrc}
          style={{
            objectFit: 'cover',
            width: '100%',
            height: 'auto',
            overflow: 'hidden',
          }}
          alt="two"
        />
      }
      portrait={false}
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        ...style,
      }}
    />
  );
};
