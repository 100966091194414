import React from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro';
import { Container as ContainerBase } from 'components/misc/Layouts.js';
import logo from '../../images/logo.svg';
import { ReactComponent as FacebookIcon } from '../../images/facebook-icon.svg';
import { ReactComponent as TwitterIcon } from '../../images/twitter-icon.svg';
import { ReactComponent as YoutubeIcon } from '../../images/youtube-icon.svg';
import { ReactComponent as InstagramIcon } from '../../images/instagram_icon.svg';

import FooterLogo from '../../images/footer-logo.png';

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-8 lg:py-10`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-32`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;
const LanguageContainer = tw.div`ml-auto text-black p-4`;
const LanguageSelect = tw.select`cursor-pointer rounded-xl border-0 bg-white py-2 px-6 text-center text-base text-black font-semibold shadow-md focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 w-32`;

const LinksContainer = tw.div`mt-4 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;
const LinkBold = tw(Link)`font-bold`;

const SocialLinksContainer = tw.div`mt-4`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const AddressText = tw.p`text-center mt-6 font-medium tracking-wide text-sm text-gray-600`;

const CopyrightText = tw.p`text-center mt-2 font-medium tracking-wide text-sm text-gray-600`;

export default () => {
  const { t, i18n } = useTranslation();

  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={FooterLogo} />
          </LogoContainer>
          <LinksContainer>
            {/* <Link
              href="./terms/manboo-serviceTerms-210617.html"
              target="_blank"
            >
              이용약관
            </Link> */}
            <LinkBold
              href="./terms/makepic-privacyTerms-230613.html"
              target="_blank"
            >
              {t('privacy')}
            </LinkBold>
            <Link
              href="#"
              onClick={() => {
                window.location.href = 'mailto:makepic@rocketworks.io';
              }}
            >
              {t('contact')}
            </Link>
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink
              href="https://www.instagram.com/makepic.ai"
              target="_blank"
            >
              <InstagramIcon />
            </SocialLink>
            <SocialLink
              href="https://www.youtube.com/@makepicai"
              target="_blank"
            >
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>
          <AddressText>{t('address')}</AddressText>
          <CopyrightText>&copy; {t('copyright')}</CopyrightText>
          {/* <LanguageContainer>
            <LanguageSelect id="language" name="language">
              <option value="kr" selected>
                한국어
              </option>
              <option value="en">영어</option>
            </LanguageSelect>
          </LanguageContainer> */}
        </Row>
      </Content>
    </Container>
  );
};
