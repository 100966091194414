import { ReservationBtnUrl } from 'Constant';
import React, { useState } from 'react';
import tw from 'twin.macro';

const Button = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-purple-600 text-gray-100 hocus:bg-purple-800 focus:shadow-outline focus:outline-none transition duration-300`;

export default ({ ...props }) => {
  return (
    <Button as="a" href={ReservationBtnUrl} target="_blank" {...props}>
      사전 예약
    </Button>
  );
};
