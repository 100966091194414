import AnimationRevealPage from 'helpers/AnimationRevealPage';
import Header from 'components/headers/light.js';
import Footer from 'components/footers/MiniCenteredFooter';
import ImgCompGrid from 'components/cards/ImgCompGrid';

export default ({}) => {
  return (
    <AnimationRevealPage disabled>
      <Header />
      <ImgCompGrid />
      <Footer />
    </AnimationRevealPage>
  );
};
