import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts.js';
import { SectionHeading } from 'components/misc/Headings.js';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';
import { ReactComponent as StarIcon } from 'images/star-icon.svg';
import { ReactComponent as SvgDecoratorBlob1 } from 'images/svg-decorator-blob-5.svg';
import { ReactComponent as SvgDecoratorBlob2 } from 'images/svg-decorator-blob-7.svg';
import 'slick-carousel/slick/slick-theme.css';

import {
  IntroStreetMdModel010,
  IntroStreetMdMode010BgRemove,
  IntroStreetMdModel010_1,
  IntroStreetMdModel010_etc,
  IntroFamousMdModel037,
  IntroFamousMdModel037BgRemove,
  IntroFamousMdModel037_1,
  IntroFamousMdModel037_etc,
  IntroIndoorModelGirl,
  IntroIndoorModelGirlBgRemove,
  IntroIndoorModelGirl_1,
  IntroIndoorModelGirl_etc,
  IntroMdModel008,
  IntroMdModel008BgRemove,
  IntroMdModel008_1,
  IntroMdModel008_etc,
  IntroMdModel023,
  IntroMdModel023BgRemove,
  IntroMdModel023_1,
  IntroMdModel023_etc,
} from 'images/app/sample/sample-res.js';

import TransBgImg from 'images/transparent.png';

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-6 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${(props) => props.active && tw`bg-primary-500! text-gray-100!`}
`;

const TabContent = tw(
  motion.div
)`mt-0 md:mt-4 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-4 w-full sm:w-1/2 md:w-1/2 lg:w-1/4 sm:pr-3 md:pr-4 lg:pr-6 rounded-2xl overflow-hidden relative hidden md:block`;

const MobileCardContainer = tw.div`mt-4 relative block md:hidden w-full`;
const MObileContentContainer = tw.div`relative py-4 lg:py-10 px-2`;

const Card = tw(
  motion.a
)`block max-w-xs mx-auto sm:max-w-none sm:mx-0 bg-white`;
const CardImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url('${props.imageSrc}');
    `}
  ${tw`h-96 bg-center bg-cover relative rounded-2xl overflow-hidden`}
`;
/*
const TransParentDiv = tw.div`relative bg-["url$(TransBgImg)"]`;
*/
const TransparentDiv = styled.div`
  ${tw`rounded-2xl w-full`}
  ${(props) => css`
    background-image: ${props.isVisible ? `url(${TransBgImg})` : 'none'};
    background-repeat: round;
  `}
`;

const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-3 text-gray-900`;
const CardContent = tw.p`mt-0 text-base font-medium text-gray-700 text-center`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

const ContentSlider = styled(Slider)`
  ${tw`mt-0`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto`}
  }
  .slick-dots {
    bottom: -0.2rem;
  }
`;

export default ({
  headingKey = 'TabBgTitle',
  tabs = {
    famous: [
      IntroFamousMdModel037,
      IntroFamousMdModel037BgRemove,
      IntroFamousMdModel037_1,
      IntroFamousMdModel037_etc,
    ],
    street: [
      IntroStreetMdModel010,
      IntroStreetMdMode010BgRemove,
      IntroStreetMdModel010_1,
      IntroStreetMdModel010_etc,
    ],
    indoor: [
      IntroIndoorModelGirl,
      IntroIndoorModelGirlBgRemove,
      IntroIndoorModelGirl_1,
      IntroIndoorModelGirl_etc,
    ],
    nature: [
      IntroMdModel008,
      IntroMdModel008BgRemove,
      IntroMdModel008_1,
      IntroMdModel008_etc,
    ],
    cafe: [
      IntroMdModel023,
      IntroMdModel023BgRemove,
      IntroMdModel023_1,
      IntroMdModel023_etc,
    ],
  },
}) => {
  const { t, i18n } = useTranslation();
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  const heading = t(headingKey);
  const subtitleKeys = ['original', 'bgremove', 'bgaigen', 'bggenmore'];

  let firstClientX, clientX;
  const preventTouch = (e) => {
    const minValue = 10; // threshold

    clientX = e.touches[0].clientX - firstClientX;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;

      return false;
    }
  };

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
  };

  let containerRef = React.createRef();
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    touchThreshold: 10,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('touchstart', touchStart);
      containerRef.current.addEventListener('touchmove', preventTouch, {
        passive: false,
      });
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('touchstart', touchStart);
        containerRef.current.removeEventListener('touchmove', preventTouch, {
          passive: false,
        });
      }
    };
  }, []);

  return (
    <Container>
      <ContentWithPaddingXl ref={containerRef}>
        <HeaderRow>
          <Header>{heading}</Header>
          <TabsControl>
            {Object.keys(tabs).map((tabName, index) => (
              <TabControl
                key={index}
                active={activeTab === tabName}
                onClick={() => setActiveTab(tabName)}
              >
                #{t(`${tabName}_tabtitle`)}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>

        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale: 1,
                display: 'flex',
              },
              hidden: {
                opacity: 0,
                scale: 0.8,
                display: 'none',
              },
            }}
            transition={{ duration: 0.6 }}
            initial={activeTab === tabKey ? 'current' : 'hidden'}
            animate={activeTab === tabKey ? 'current' : 'hidden'}
          >
            {tabs[tabKey].map((card, index) => (
              <>
                <CardContainer key={index}>
                  <Card className="group">
                    <TransparentDiv isVisible={index == 1}>
                      <CardImageContainer imageSrc={card} />
                    </TransparentDiv>
                    <CardText>
                      <CardContent>{t(subtitleKeys[index])}</CardContent>
                    </CardText>
                  </Card>
                </CardContainer>
              </>
            ))}
            <MobileCardContainer>
              <ContentSlider {...sliderSettings}>
                {tabs[tabKey].map((card, index) => (
                  <MObileContentContainer key={index}>
                    <TransparentDiv isVisible={index == 1}>
                      <CardImageContainer imageSrc={card} />
                    </TransparentDiv>
                    <CardText>
                      <CardContent>{t(subtitleKeys[index])}</CardContent>
                    </CardText>
                  </MObileContentContainer>
                ))}
              </ContentSlider>
            </MobileCardContainer>
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
