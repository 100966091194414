import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { ContentWithPaddingXl, Container } from 'components/misc/Layouts.js';
import {
  SectionHeading as Heading,
  Subheading as SubheadingBase,
} from 'components/misc/Headings.js';
import { ReactComponent as SvgDecoratorBlob1 } from 'images/svg-decorator-blob-7.svg';
import { ReactComponent as SvgDecoratorBlob2 } from 'images/svg-decorator-blob-8.svg';
import 'slick-carousel/slick/slick-theme.css';

const Subheading = tw(SubheadingBase)`text-center`;
const Testimonials = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch`;
const TestimonialContainer = tw.div`mt-5 border-2 rounded-2xl py-4 lg:py-10 px-6 h-[340px] lg:h-[305px]`;
const Testimonial = tw.div`px-4 text-center max-w-xs mx-auto flex flex-col items-center `;
const Image = tw.img`w-20 h-20 rounded-full`;
const Quote = tw.blockquote`mt-5 text-gray-600 font-medium leading-loose`;
const CustomerName = tw.p`mt-5 text-gray-900 font-semibold uppercase text-sm tracking-wide`;

const ContentSlider = styled(Slider)`
  ${tw`mt-0`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1 mx-4`}
  }
  .slick-dots {
    bottom: -1.5rem;
  }
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;

export default ({
  subheading = null,
  heading = '고객 리뷰',
  testimonials = [
    {
      quote:
        '촬영하려고 하면 조명이랑 장비 셋팅하느라 힘들었는데 이제 그런 수고로움과 비용 부담에서 해방됐어요. 버튼 하나만 누르면 자동으로 생성되고 보정까지되니까 누구나 쉽게 쓸 수 있다는 점도 마음에 들어요.',
      customerName: '쇼핑몰 운영자 H씨',
    },
    {
      quote:
        '상세페이지 제작하려고 하는데 괜찮은 스튜디오 찾기 힘들었거든요. 근데 여긴 장소 대여비 따로 없고 보정작업 다 해주니까 가격 부담 없어서 좋았어요.',
      customerName: '마케터 K씨',
    },
    {
      quote:
        'SNS 운영하다보면 예쁜 배경에서 찍은 상품사진 올리고 싶은데 폰카로는 한계가 있잖아요. 그럴 때 요거 쓰면 전문가 못지 않게 고퀄리티 결과물 얻을 수 있어요. 테마도 다양해서 취향대로 골라 쓸 수 있답니다.',
      customerName: '인플루언서 A씨',
    },
  ],
}) => {
  let firstClientX, clientX;
  const preventTouch = (e) => {
    const minValue = 10; // threshold

    clientX = e.touches[0].clientX - firstClientX;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;

      return false;
    }
  };

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
  };

  let containerRef = React.createRef();
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    dots: true,
    arrows: false,
    speed: 300,
    slidesToShow: 3,
    touchThreshold: 10,
    infinite: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('touchstart', touchStart);
      containerRef.current.addEventListener('touchmove', preventTouch, {
        passive: false,
      });
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('touchstart', touchStart);
        containerRef.current.removeEventListener('touchmove', preventTouch, {
          passive: false,
        });
      }
    };
  }, []);

  return (
    <Container tw="mb-12">
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        <div tw="w-full" ref={containerRef}>
          <ContentSlider ref={setSliderRef} {...sliderSettings}>
            {testimonials.map((testimonial, index) => (
              <TestimonialContainer key={index}>
                <Testimonial>
                  {/* <Image src={testimonial.imageSrc} /> */}
                  <Quote>"{testimonial.quote}"</Quote>
                  <CustomerName>- {testimonial.customerName}</CustomerName>
                </Testimonial>
              </TestimonialContainer>
            ))}
          </ContentSlider>
        </div>
      </ContentWithPaddingXl>

      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
