import React, { useState } from 'react';
import { motion } from 'framer-motion';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts.js';
import { SectionHeading } from 'components/misc/Headings.js';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';
import { ReactComponent as StarIcon } from 'images/star-icon.svg';
import { ReactComponent as SvgDecoratorBlob1 } from 'images/svg-decorator-blob-5.svg';
import { ReactComponent as SvgDecoratorBlob2 } from 'images/svg-decorator-blob-7.svg';

import ImgComp from 'components/misc/ImgComp';

// sample image import

import {
  StreetModel010,
  StreetModel010Rst,
  StreetModel023,
  StreetModel023Rst,
  StudioModel025,
  StudioModel025Rst,
  StudioModel035,
  StudioModel035Rst,
  NatureMode019,
  NatureMode019Rst,
  NatureMode041,
  NatureMode041Rst,
  MoodMode005,
  MoodMode005Rst,
  MoodMode027,
  MoodMode027Rst,
  FamousMode037,
  FamousMode037Rst,
  FamousMode052,
  FamousMode052Rst,
  CafeMode029,
  CafeMode029Rst,
  EtcGirlOrig,
  EtcGirlTrans,
} from 'images/app/sample/sample-res.js';

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${(props) => props.active && tw`bg-primary-500! text-gray-100!`}
`;

const TabContent = tw(
  motion.div
)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12 gap-6`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(
  motion.a
)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url('${props.imageSrc}');
    `}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

const ImgCompSamples = [
  {
    type: 'street',
    orig: StreetModel010,
    trans: StreetModel010Rst,
  },
  {
    type: 'street',
    orig: StreetModel023,
    trans: StreetModel023Rst,
  },

  {
    type: 'studio',
    orig: StudioModel025,
    trans: StudioModel025Rst,
  },
  {
    type: 'studio',
    orig: StudioModel035,
    trans: StudioModel035Rst,
  },
  {
    type: 'nature',
    orig: NatureMode019,
    trans: NatureMode019Rst,
  },
  {
    type: 'nature',
    orig: NatureMode041,
    trans: NatureMode041Rst,
  },
  {
    type: 'mood',
    orig: MoodMode005,
    trans: MoodMode005Rst,
  },
  {
    type: 'mood',
    orig: MoodMode027,
    trans: MoodMode027Rst,
  },
  {
    type: 'famous',
    orig: FamousMode037,
    trans: FamousMode037Rst,
  },
  {
    type: 'famous',
    orig: FamousMode052,
    trans: FamousMode052Rst,
  },
  {
    type: 'cafe',
    orig: CafeMode029,
    trans: CafeMode029Rst,
  },
  {
    type: 'etc',
    orig: EtcGirlOrig,
    trans: EtcGirlTrans,
  },
];

export default ({}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header>사용예시</Header>
        </HeaderRow>
        <TabContent>
          {ImgCompSamples.map((item, index) => (
            <ImgComp
              key={index}
              style={{
                maxWidth: '530px',
                maxHeight: '700px',
                borderRadius: '2.5rem',
              }}
              itemOneSrc={item.orig}
              itemTwoSrc={item.trans}
            />
          ))}
        </TabContent>
      </ContentWithPaddingXl>
    </Container>
  );
};
