import React from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import ClickImg from 'images/clicker-1.svg';

import AnimationRevealPage from 'helpers/AnimationRevealPage';
import Hero from 'components/hero/TwoColumnWithImgComp.js';
import Blog from 'components/blogs/GridWithFeaturedPost.js';
//import Features from 'components/features/ThreeColSimple.js';
// import SixFeatures from 'components/features/DashedBorderSixFeatures';
import MainFeature from 'components/features/TwoColWithButton.js';
// import MainFeature2 from 'components/features/TwoColSingleFeatureWithStats2.js';
// import ImgCompFeature from 'components/features/TwoColWithTwoImage.js';
// import TabGrid from 'components/cards/TabCardGrid.js';
import Testimonial from 'components/testimonials/ThreeColumnWithProfileImage.js';
import FAQ from 'components/faqs/SingleCol.js';

// import DownloadApp from 'components/cta/DownloadApp.js';
import GetStarted from 'components/cta/GetStarted.js';
import Footer from 'components/footers/MiniCenteredFooter';
// import ImgComp from 'components/misc/ImgComp';
import noLicenseIconImageSrc from 'images/034-confidential.svg';
import privacyIconImageSrc from 'images/023-police.svg';
import technicalImageSrc from 'images/015-medal.svg';
import {
  NatureMode019,
  NatureMode019Rst,
} from 'images/app/sample/sample-res.js';
import TabCardGrid from 'components/cards/TabCardGrid.js';

import ToolFeature from 'components/features/ImageCompareToolFeatures';
import FeatureBgRemoveMp4 from 'images/0706_feature_bgremove.mp4';
import FeatureBgOfHumanReplaceMp4 from 'images/0706_feature_bgOfHuman_replace.mp4';
import FeatureBgOfProductReplaceMp4 from 'images/0706_feature_bgOfProduct_replace.mp4';
import FeatureHumanFaceMp4 from 'images/0706_feature_face_relplace.mp4';

// import FeatureModelCutImg from 'images/feature_modelcut_img_1.jpg';
import FeatureModelCutImg from 'images/0706_feature_modelcut_img.jpg';
import { ReservationBtnUrl } from 'Constant';

export default ({}) => {
  const { t, i18n } = useTranslation();

  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`text-primary-500 transform inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl max-h-[500px] w-full`;
  const CenterTitleHeading = tw.h1`text-center font-bold text-3xl md:text-5xl mt-0 py-2 md:py-6`;
  const AlertNo = <span tw="text-red-600">NO</span>;

  return (
    <AnimationRevealPage disabled>
      <Hero
        heading={
          <>
            성공하는 커머스에
            <br />
            도움이 되는
            <br />
            AI 기술을 만들어 갑니다
          </>
        }
        description={
          <Description>
            커머스 산업에 시간과 비용 절감을 넘어서
            <br />
            수익 극대화와 뛰어난 고객 경험을 지향합니다.
            <br />
            <br />
            사전예약하셔서 가장 빠른 소식을 받아보세요.
            <br />
          </Description>
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText={t('try_button')}
        target="_blank"
      />
      {/* <CenterTitleHeading>Featured AI Photo Tools</CenterTitleHeading> */}
      {/* <ToolFeature /> */}
      <MainFeature
        subheading="상품 배경 생성"
        heading={
          <>
            고객의 마음을 사로잡는 <br />
            상품 배경 생성
          </>
        }
        description={
          <>
            전문 작가처럼 멋진 배경을 생성하고
            <br />
            AI 조명 처리로 상품을 더 돋보이게 도와 드립니다.
            <br />
          </>
        }
        videoSrc={FeatureBgOfProductReplaceMp4}
        primaryButtonText="상품 배경 생성하기"
        primaryButtonUrl="https://service.makepic.ai/replace-product-background"
        textOnLeft={false}
      />

      <MainFeature
        subheading="인물 배경 생성"
        heading={
          <>
            시간과 날씨 제약 없이
            <br />
            인물 배경 생성
          </>
        }
        description={
          <>
            다양한 테마를 활용하여 맞춤형 배경을 생성해 드립니다.
            <br />
            자연광 조명 처리 기술로 더욱 매력적인 사진을 제공합니다.
          </>
        }
        videoSrc={FeatureBgOfHumanReplaceMp4}
        primaryButtonText="인물 배경 생성하기"
        primaryButtonUrl="https://service.makepic.ai/replace-person-background"
        textOnLeft={true}
      />

      <MainFeature
        subheading="버츄얼 얼굴 생성"
        heading={
          <>
            초상권 걱정없는
            <br />
            모델 얼굴 생성
          </>
        }
        description={
          <>
            기존 모델을 대체하여 개성 있는 버츄얼 얼굴을 생성합니다
            <br />
            비용 걱정없이 광고와 프로모션에 활용하세요.
            <br />
          </>
        }
        videoSrc={FeatureHumanFaceMp4}
        // imageSrc={FeatureHumanFaceImg}
        // imagecss={imageCss}
        primaryButtonText="얼굴 생성하기"
        primaryButtonUrl="https://service.makepic.ai/change-face"
        textOnLeft={false}
      />

      <MainFeature
        subheading="AI 배경 제거"
        heading={
          <>
            누구나 손쉽게
            <br />
            정확한 배경 제거
          </>
        }
        description={
          <>
            AI 알고리즘이 이미지의 배경을 손쉽게 제거해 드립니다.
            <br />
            포토샵과 같은 외부 툴 없이 손쉽게 활용하세요.
            <br />
          </>
        }
        videoSrc={FeatureBgRemoveMp4}
        primaryButtonText="배경 제거하기"
        primaryButtonUrl="https://service.makepic.ai/remove-background"
        textOnLeft={true}
      />

      <MainFeature
        subheading="모델 컷"
        heading={
          <>
            마네킹 사진을 멋진
            <br />
            모델 컷으로 제작 ( 예정 )
          </>
        }
        description={
          <>
            마네킹 사진을 고퀄리티 모델컷으로 생성하는 서비스를 준비중입니다.
            <br />
            가장 먼저 사용해 보고 싶으시다면 사전 예약 신청을 하세요.
            <br />
          </>
        }
        imageSrc={FeatureModelCutImg}
        imagecss={imageCss}
        imageAlt="모델 사진 제작"
        primaryButtonText="사전 예약"
        primaryButtonUrl={ReservationBtnUrl}
        primaryButtonCss={tw`bg-purple-600 text-gray-100 hocus:bg-purple-800`}
        textOnLeft={false}
      />

      <TabCardGrid />

      {/* <Features
        heading="간편하고 안전한 상품 사진 생성"
        cards={[
          {
            imageSrc: technicalImageSrc,
            title: (
              <>
                {t('feature_1_subtitle')} {AlertNo}
              </>
            ),
            description: t('feature_1_description'),
          },
          {
            imageSrc: noLicenseIconImageSrc,
            title: (
              <>
                {t('feature_2_subtitle')} {AlertNo}
              </>
            ),
            description: t('feature_2_description'),
          },
          {
            imageSrc: privacyIconImageSrc,
            title: (
              <>
                {t('feature_3_subtitle')} {AlertNo}
              </>
            ),
            description: t('feature_3_description'),
          },
        ]}
        linkText={null}
        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      /> 

      {/* <ImgCompFeature
        subheading=""
        heading={
          <>
            촬영 비용 절감을 넘어 사진을 한단계
            <span tw="text-primary-500">&nbsp;업그레이드</span>
          </>
        }
        description="깔끔한 배경제거와 밝기 밸런스 조절로 조화로운 사진 연출"
        images={[NatureMode019Rst, NatureMode019]}
        imageCss={tw`rounded-4xl max-h-[500px] max-w-[375px] object-cover w-full relative`}
      /> */}
      {/* <SixFeatures /> */}
      <Testimonial />
      <GetStarted />
      <FAQ />
      <Footer />
    </AnimationRevealPage>
  );
};
