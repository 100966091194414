import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      main_feature_description_1:
        '스튜디오와 야외촬영 비용과 시간을 절약하세요. 인공지능 기반의 makepic이 상품 사진의 합성 및 보정을 효과적으로 처리해 드립니다.',
      main_feature_description_2: '지금 바로 놀라운 결과를 경험해보세요!',

      feature_1_subtitle: 'Expertise',
      feature_1_description:
        'Even if you do not have professional knowledge and Photoshop, anyone can easily create and composite backgrounds with just a click.',
      feature_2_subtitle: 'Copyright',
      feature_2_description:
        'Copyright and usage rights belong to the user, and can be used for personal and commercial purposes.',

      feature_3_subtitle: 'Privacy',
      feature_3_description:
        'Uploaded photos are automatically deleted after 60 minutes and are not stored on the server. Use it worry-free',

      // title
      TabBgTitle: '190+ backgrounds',

      // tab title
      street_tabtitle: 'Street',
      famous_tabtitle: 'Famous',
      indoor_tabtitle: 'Studio',
      nature_tabtitle: 'Nature',
      cafe_tabtitle: 'Cafe',

      original: 'Original',
      bgremove: 'Background remove',
      bgaigen: 'Ai background gen',
      bggenmore: 'More backgrounds',

      // buttons
      reservation_button: 'Reservation',
      try_button: 'Try it',

      // faq
      faq_title: '자주 묻는 질문',
      faq_question_1: '생성된 결과물에 대한 저작권은 누구에게 있나요?',
      faq_question_2: '개인정보 노출(업로드된 사진)이 걱정됩니다.',

      // footer
      copyright: 'Copyright 2023, Rocketworks Inc. All Rights Reserved.',
      address:
        '(주)로켓웍스 | 사업자등록번호: 107-87-29935 | 통신판매업신고번호: 2012-경기성남-0765 | 대표자명: 김정일',
      privacy: 'Privacy',
      contact: 'Contact us',
    },
  },
  kr: {
    translation: {
      main_feature_description_1:
        '누구나 쉽게 스튜디오나 야외 촬영 없이 시선을 사로잡는 멋진 배경 사진을 만들 수 있어요.',
      main_feature_description_2:
        'makepic의 놀라운 품질을 지금 무료로 경험하세요!',

      // feature
      feature_1_subtitle: '전문적 지식',
      feature_1_description:
        '전문 지식과 포토샵을 못해도 클릭만으로 누구나 쉽게 배경 생성과 합성을 할 수 있습니다',

      feature_2_subtitle: '저작권 걱정',
      feature_2_description:
        '저작권 및 사용 권한은 사용자에게 귀속되며, 개인적 용도 및 상업적 용도로 활용할 수 있습니다',

      feature_3_subtitle: '개인정보 노출 걱정',
      feature_3_description:
        '업로드된 사진은 60분이후 자동 삭제 되며, 서버에 저장되지 않습니다. 걱정 없이 사용하세요',

      // title
      TabBgTitle: '190+ 무료 테마',

      // tab title
      street_tabtitle: '거리',
      famous_tabtitle: '명소',
      indoor_tabtitle: '스튜디오',
      nature_tabtitle: '자연',
      cafe_tabtitle: '카페',

      original: '원본',
      bgremove: '배경 제거',
      bgaigen: '배경 생성',
      bggenmore: '더 많은 배경',

      // buttons
      reservation_button: '사전 예약',
      try_button: '시작 하기',

      // faq
      faq_title: '자주 묻는 질문',
      faq_question_1: '생성된 결과물에 대한 저작권은 누구에게 있나요?',
      faq_question_2: '개인정보 노출(업로드된 사진)이 걱정됩니다.',

      // footer
      copyright: 'Copyright 2023, Rocketworks Inc. All Rights Reserved.',
      address:
        '(주)로켓웍스 | 사업자등록번호: 107-87-29935 | 통신판매업신고번호: 2012-경기성남-0765 | 대표자명: 김정일',
      privacy: '개인정보 처리방침',
      contact: '문의하기',
    },
  },
};

i18n.use(initReactI18next).init({
  resources, // 리소스
  lng: 'kr', // 초기 설정 언어
});

export default i18n;
