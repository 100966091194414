import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line

import { ReactComponent as ArrowRightIcon } from 'images/arrow-right.svg';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';
import { OutlineButton as OutlineButtonBase } from 'components/misc/Buttons.js';
import { useMediaQuery } from 'react-responsive';

import ImgComp from 'components/misc/ImgComp.js';
import girlOrig from 'images/app/md_model_023.jpg';
import girlTrans from 'images/app/md_model_023_rst.jpg';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row gap-8 md:gap-0 justify-between max-w-screen-xl mx-auto py-2 md:py-4 items-start`;

const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const buttonStyleCss = tw`text-sm inline-block mx-auto mt-2`;

const PrimaryButton = styled(PrimaryButtonBase)((props) => [
  buttonStyleCss,
  props.buttonRounded && tw`rounded-full`,
]);

const OutlineButton = styled(OutlineButtonBase)((props) => [
  buttonStyleCss,
  props.buttonRounded && tw`rounded-full`,
]);

export default ({}) => {
  return (
    <Container>
      <TwoColumn>
        <AIToolItem
          title="AI 배경 제거"
          description={
            <>
              쉽고 빠른 놀라운 품질의 AI 배경 제거.
              <br />
              전경을 제외하고 배경을 깔끔하게 지웁니다.
            </>
          }
          primaryButtonText="배경 제거"
          primaryButtonUrl="https://service.makepic.ai/remove-background"
        />
        <AIToolItem
          title="인물 배경 교체"
          description={
            <>
              패션 화보 같은 사진 만드는 인물 배경 생성. <br /> 인물이 포함된
              사진에서 인물을 제외한 배경을 변경해 드립니다.
            </>
          }
          primaryButtonText="배경 교체"
          primaryButtonUrl="https://service.makepic.ai/replace-person-background"
        />
      </TwoColumn>
      <TwoColumn>
        <AIToolItem
          title="상품 배경 교체"
          description={
            <>
              상품을 더 돋보이게 만드는 상품 배경 생성.
              <br />
              상품을 더욱 부각시키도록 적절한 배경으로 변환해 줍니다.
            </>
          }
          primaryButtonText="배경 교체"
          primaryButtonUrl="https://service.makepic.ai/replace-product-background"
        />
        <AIToolItem
          title="얼굴 교체"
          description={
            <>
              초상권 걱정 없는 매력적인 버츄얼 얼굴 생성.
              <br />
              모델의 얼굴을 바꿔줍니다.
            </>
          }
          primaryButtonText="얼굴 변경"
          primaryButtonUrl="https://service.makepic.ai/change-face"
        />
      </TwoColumn>
    </Container>
  );
};

const StyledButton = ({ text, url, isOutline = false, ...props }) => {
  return isOutline ? (
    <OutlineButton href={url} {...props}>
      {text}
    </OutlineButton>
  ) : (
    <PrimaryButton href={url} {...props}>
      {text}
    </PrimaryButton>
  );
};

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const AIToolItem = ({
  title = <>AI 배경 제거</>,
  description = '원하지 않는 배경을 깔끔하게 제거합니다.',
  isOutlineButton = false,
  primaryButtonText = 'Learn More',
  primaryButtonUrl = '/moresample',
}) => {
  const Item = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-6`;
  const ImageContent = tw.div`relative w-full max-h-[400px] overflow-hidden`;
  const TextContent = tw.div`lg:py-2 md:text-left text-gray-800 mx-1 md:mx-2`;

  const Title = tw.h5`font-bold text-xl md:text-2xl mt-2 `;
  const SubTitle = tw.p`text-left text-[16px] md:text-lg font-normal leading-relaxed opacity-50`;

  const isMdUnder = useMediaQuery({
    query: '(min-width:0px) and (max-width:768px)',
  });

  const maxHeight = isMdUnder ? '300px' : '400px';

  return (
    <Item>
      <ImageContent>
        <ImgComp
          style={{
            maxHeight: maxHeight,
            borderRadius: '1rem',
          }}
          itemOneSrc={girlOrig}
          itemTwoSrc={girlTrans}
        />
      </ImageContent>

      <TextContent>
        <Title>{title}</Title>
        <SubTitle>{description}</SubTitle>
        <StyledButton
          isOutline={true}
          buttonRounded={false}
          as="a"
          url={primaryButtonUrl}
          text={primaryButtonText}
        />
      </TextContent>
    </Item>
  );
};
