import GlobalStyles from 'styles/GlobalStyles';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from 'pages/Home';
import MoreSample from 'pages/MoreSample';

function App() {
  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route exact path="/moresample" element={<MoreSample />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
